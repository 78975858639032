import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import { getActionRedirectPath } from '../../utils/payment';
import { pushTag } from '../../utils/gtm';
import Spinner from '../Spinner';


class PaymentAction extends Component {
  constructor(props) {
    super(props);
    const { fallbackPath } = props;

    this.state = {
      redirectPath: fallbackPath,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { match, location, action } = this.props;
    const { search } = location;

    getActionRedirectPath({ params: { paymentAction: action, ...match }, search })
      .then(({ gaData, redirectPath }) => {
        if (gaData && gaData.length !== 0) {
          pushTag(gaData[0], gaData[1]);
        }
        this.setState({
          redirectPath,
          isLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  /**
   * @todo add handler for `children` as function
   */
  render() {
    const { placeholder: Placeholder } = this.props;
    const { redirectPath, isLoading } = this.state;

    if (isLoading) return <Placeholder />;

    navigate(redirectPath);

    return null;
  }
}

PaymentAction.propTypes = {
  match: PropTypes.shape({
    paymentId: PropTypes.string,
    paymentAction: PropTypes.string,
    paymentMethod: PropTypes.string,
    orderId: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  action: PropTypes.string.isRequired,
  fallbackPath: PropTypes.string,
  placeholder: PropTypes.func,
};

PaymentAction.defaultProps = {
  fallbackPath: '/',
  placeholder: Spinner,
};

export default PaymentAction;
