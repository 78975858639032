import React from 'react';
import { Match } from '@reach/router';
import { Link } from 'gatsby';
import { PaymentAction, PaymentStatus } from '../../components/Payment';
import Layout from '../../layouts/SimplePage';

import './index.scss';


const fallbackPath = '/dashboard/';

const PaymentPage = () => (
  <Layout
    pageWrapperClassName="payment-page"
    headerClassName="header_white-bg"
  >
    <section className="payment-section">
      <div className="payment-section__container container">
        <Match path="/payment/payment_system_cancel/:paymentId">
          {({ location, match }) => match && (
            <PaymentAction
              action="cancel"
              match={match}
              location={location}
              fallbackPath={fallbackPath}
            />
          )}
        </Match>
        <Match path="/payment/payment_system_return/:orderId/:paymentMethod/:paymentId">
          {({ location, match }) => match && (
            <PaymentAction
              action="return"
              match={match}
              location={location}
              fallbackPath={fallbackPath}
            />
          )}
        </Match>
        <Match path="/payment/failed/:orderId">
          {({ location, match }) => match && (
            <PaymentStatus
              status="failed"
              match={match}
              location={location}
              fallbackPath={fallbackPath}
              render={({ to, countdown }) => (
                <div className="payment-section__result-block">
                  <h2 className="payment-section__heading payment-section__heading_failed">
                    Payment failed :(
                  </h2>
                  <div className="payment-section__counter">
                    {countdown}
                  </div>
                  <div className="payment-section__redirect-info">
                    <p className="payment-section__redirect-info__paragraph">
                      Thank you for using our service.
                      <br />
                      {' '}
                      Redirecting to
                      {' '}
                      <Link to={to}>
                        your order page
                      </Link>
                      …
                    </p>
                  </div>
                </div>
              )}
            />
          )}
        </Match>
        <Match path="/payment/successful/:orderId">
          {({ location, match }) => match && (
            <PaymentStatus
              status="successful"
              match={match}
              location={location}
              fallbackPath={fallbackPath}
              render={({ to, countdown }) => (
                <div className="payment-section__result-block">
                  <h2 className="payment-section__heading payment-section__heading_successful">
                    Payment successful :)
                  </h2>
                  <div className="payment-section__counter">
                    {countdown}
                  </div>
                  <div className="payment-section__redirect-info">
                    <p className="payment-section__redirect-info__paragraph">
                      Thank you for using our service.
                      <br />
                      {' '}
                      Redirecting to
                      {' '}
                      <Link to={to}>
                        your order page
                      </Link>
                      …
                    </p>
                  </div>
                </div>
              )}
            />
          )}
        </Match>
      </div>
    </section>
  </Layout>
);

export default PaymentPage;
