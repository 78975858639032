import fetch from './fetch';

const apiURL = '/api';
const baseURL = `${apiURL}/payment`;
const defaultOptions = {
  method: 'GET',
  headers: {
    Accept: 'text/html',
  },
  credentials: 'same-origin',
};


const parseDoc = (text) => {
  const parser = new DOMParser();

  return parser.parseFromString(text, 'text/html');
};

const getRedirectURL = (doc) => {
  if (!doc) throw new Error('Invalid Document');

  const anchorElement = doc.querySelector('[data-redirect-url]');
  const gaElement = doc.querySelector('[data-ecomerce]');

  if (!anchorElement) throw new Error('Anchor Element is not defined');

  const redirectURL = anchorElement.href;

  if (!redirectURL) throw new Error('Redirect URL is missing');

  const { origin } = window.location;

  let gaData;
  let redirectPath = redirectURL.indexOf(origin) === 0
    ? redirectURL.slice(origin.length)
    : redirectURL;

  redirectPath = redirectPath.indexOf(apiURL) === 0
    ? redirectPath.slice(apiURL.length)
    : redirectPath;

  if (gaElement) {
    try {
      const attr = gaElement.getAttribute('data-ecomerce');
      const json = JSON.parse(attr);
      gaData = ['ecommerce', {
        purchase: {
          actionField: json.transaction,
          products: [json.item],
        },
      }];
    } catch (e) {
      // optional, no need to reject
    }
  }

  return { gaData, redirectPath };
};

export const getActionRedirectPath = ({ params, search }) => {
  const {
    paymentAction,
    paymentMethod,
    paymentId,
    orderId,
  } = params;
  const urlSegments = [
    baseURL,
    `payment_system_${paymentAction}`,
    orderId,
    paymentMethod,
    paymentId,
  ].filter(Boolean);
  const url = `${urlSegments.join('/')}${search}`;
  const options = Object.assign({}, defaultOptions);

  return fetch(url, options)
    .then(response => response.text())
    .then(parseDoc)
    .then(getRedirectURL);
};

export const getStatusRedirectPath = ({ params, search }) => {
  const {
    paymentStatus,
    orderId,
    paymentMethodId,
  } = params;
  const urlSegments = [
    baseURL,
    paymentStatus,
    orderId,
    paymentMethodId,
  ].filter(Boolean);
  const url = `${urlSegments.join('/')}${search}`;
  const options = Object.assign({}, defaultOptions);

  return fetch(url, options)
    .then(response => response.text())
    .then(parseDoc)
    .then(getRedirectURL);
};
