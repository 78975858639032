import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import { getStatusRedirectPath } from '../../utils/payment';
import { pushTag } from '../../utils/gtm';
import Spinner from '../Spinner';


class PaymentStatus extends Component {
  constructor(props) {
    super(props);

    this.startCountdown = this.startCountdown.bind(this);
    this.stopCountdown = this.stopCountdown.bind(this);

    const { countdown, fallbackPath } = props;

    this.state = {
      countdown,
      redirectPath: fallbackPath,
      isLoading: true,
    };
  }

  componentDidMount() {
    const { match, location, status } = this.props;
    const { search } = location;

    getStatusRedirectPath({ params: { paymentStatus: status, ...match }, search })
      .then(({ gaData, redirectPath }) => {
        if (gaData && gaData.length !== 0) {
          pushTag(gaData[0], gaData[1]);
        }

        this.setState({
          redirectPath,
          isLoading: false,
        });
      })
      .then(this.startCountdown)
      .catch(() => {
        this.setState({
          countdown: 0,
          isLoading: false,
        });
      });
  }

  componentWillUnmount() {
    this.stopCountdown();
  }

  startCountdown() {
    this.coutndown = setInterval(() => {
      this.setState(
        prev => ({ countdown: prev.countdown - 1 }),
        () => {
          const { countdown } = this.state;
          if (countdown > 0) return;

          this.stopCountdown();
        },
      );
    }, 1000);
  }

  stopCountdown() {
    if (this.coutndown) {
      clearInterval(this.coutndown);

      this.coutndown = undefined;
    }
  }

  /**
   * @todo add handler for `children` as function
   */
  render() {
    const {
      render,
      placeholder: Placeholder,
    } = this.props;
    const {
      countdown,
      redirectPath,
      isLoading,
    } = this.state;

    if (isLoading) return <Placeholder />;

    if (countdown > 0) return render({ countdown, to: redirectPath });

    navigate(redirectPath);

    return null;
  }
}

PaymentStatus.propTypes = {
  match: PropTypes.shape({
    paymentStatus: PropTypes.string,
    orderId: PropTypes.string,
    paymentMethodId: PropTypes.string,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  status: PropTypes.string.isRequired,
  countdown: PropTypes.number,
  fallbackPath: PropTypes.string,
  render: PropTypes.func,
  placeholder: PropTypes.func,
};

PaymentStatus.defaultProps = {
  countdown: 10,
  fallbackPath: '/',
  render: () => null,
  placeholder: Spinner,
};

export default PaymentStatus;
